<template>
  <b-modal
    id="feeling-modal"
    @show="handleGetDetail"
    ref="feeling-modal"
    :title="isEditing ? detail.name : 'Tạo cảm xúc'"
  >
    <b-overlay :show="loading">
      <b-container fluid class="p-0">
        <feeling-form
          ref="form"
          :data="detail"
          :isEditing="isEditing"
          @updateForm="updateForm"
          @updateInactive="updateInactive"
          :categories="categories"
        />
      </b-container>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          :variant="detail.isActive ? 'danger' : 'primary'"
          v-if="isEditing"
          class="btn btn-inactive"
          href="#"
          @click="handleInactive"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          {{ detail.isActive ? 'Inactive' : 'Active' }}
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          href="#"
          @click="handleClick"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          {{ isEditing ? ' Cập nhật' : 'Lưu' }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import FeelingForm from './Form';
export default {
  name: 'Modal',
  components: { 'feeling-form': FeelingForm },
  props: {
    popupType: {
      type: String,
      default: 'detail',
    },
    id: {
      type: String,
      default: null,
    },
    emotionType: { type: Number },
  },
  computed: {
    isEditing() {
      return this.popupType == 'detail' ? true : false;
    },
  },
  watch: {},
  mounted() {},
  data() {
    return {
      categories: [
        {
          name: 'Cảm xúc',
          value: 0,
        },
        {
          name: 'Hoạt động',
          value: 1,
        },
        {
          name: 'Triệu chứng',
          value: 2,
        },
      ],
      isMounted: false,
      loading: false,
      detail: {
        title: '',
      },
      form: {},
      validationState: {},
      error: {},
    };
  },
  methods: {
    handleInactive() {
      this.$refs.form.updateInactive();
    },
    updateInactive(payload) {
      this.handleSubmit(payload);
    },
    handleClick() {
      this.$refs.form.updateForm();
    },
    updateForm(payload) {
      this.handleSubmit(payload);
    },
    async handleSubmit(payload) {
      this.loading = true;
      try {
        if (!this.isEditing) {
          await this.$api.post('Admin/Emotion', payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          await this.$api.put('Admin/Emotion', payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
        this.$toastr.s({
          title: 'Thành công!',
          msg: `${this.isEditing ? 'Cập nhật ' : 'Tạo'} thành công`,
        });
        this.$emit('loadData');
        this.$bvModal.hide('feeling-modal');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    handleGetDetail() {
      if (this.id) {
        this.getDetail();
      }
    },
    getDetail() {
      this.loading = true;
      this.isMounted = false;
      this.$store.commit('context/setLoading', true);
      this.$api
        .get(`Admin/Emotion/${this.id}?emotionType=${this.emotionType}`)
        .then(({ data }) => {
          this.detail = data;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi!',
            msg: error,
          });
        })
        .finally(() => {
          this.isMounted = true;
          this.loading = false;
        });
      return;
    },
  },
};
</script>

<style lang="scss">
#glucose-modal {
  .modal-dialog {
    width: 370px;
    height: 582px;
  }
}
</style>
